import { ArrowForwardRounded } from '@mui/icons-material'
import { Card, CardMedia, Container, Grid, Typography } from '@mui/material'
import { ButtonEx, FlexGrowCol } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

import { useSettings } from '../../Contexts'
import {
  secLogo,
  tzeroLogoBlack,
  tzeroLogoWhite,
  vertaloLogoColor,
  vertaloLogoWhite,
  xyLabsInvestorRelationsLogoBlack,
  xyLabsInvestorRelationsLogoWhite,
} from './img'

const InvestorsResourcesSection: React.FC = () => {
  const { darkMode } = useSettings()
  return (
    <SectionSpacingRow>
      <Container>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={5}>
            <FlexGrowCol width="100%" alignItems="flex-start" paddingY={2}>
              <Typography variant="h2" gutterBottom>
                Looking for something?
              </Typography>
              <Typography variant="h6" gutterBottom>
                From transfer agent to trading systems, we find industry-leading platforms to bring the best and most secure experience to our
                shareholders.
              </Typography>
              <ButtonEx marginTop={1} variant="contained" color="primary" to="/investors/resources" endIcon={<ArrowForwardRounded />}>
                Explore Investor Resources
              </ButtonEx>
            </FlexGrowCol>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} lg={6}>
                <Card>
                  <CardMedia component="img" width="100%" image={darkMode ? vertaloLogoWhite : vertaloLogoColor} alt="Vertalo Logo" />
                </Card>
              </Grid>
              <Grid item xs={6} sm={6} lg={6}>
                <Card>
                  <CardMedia component="img" width="100%" image={darkMode ? tzeroLogoWhite : tzeroLogoBlack} alt="tZERO Logo" />
                </Card>
              </Grid>
              <Grid item xs={6} sm={6} lg={6}>
                <Card>
                  <CardMedia component="img" width="100%" image={secLogo} alt="SEC Logo" />
                </Card>
              </Grid>
              <Grid item xs={6} sm={6} lg={6}>
                <Card>
                  <CardMedia
                    component="img"
                    width="100%"
                    image={darkMode ? xyLabsInvestorRelationsLogoWhite : xyLabsInvestorRelationsLogoBlack}
                    alt="XY Labs Investor Relations Logo"
                  />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </SectionSpacingRow>
  )
}

export default InvestorsResourcesSection
