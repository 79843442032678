import { Link, Typography } from '@mui/material'
import { FlexGrowCol, FlexRow } from '@xylabs/sdk-react'
import React from 'react'

import JobsBasePage from '../JobsBasePage'

const ApplyPage: React.FC = () => {
  return (
    <JobsBasePage name="Apply" container="xl">
      <FlexGrowCol padding={2} justifyContent="flex-start" alignItems="stretch">
        <FlexRow>
          <Typography variant="h1">Apply for a position at XY Labs</Typography>
        </FlexRow>
        <FlexRow margin={2}>
          <Typography variant="body1">
            Please send an email to
            {' '}
            <Link href="mailto:careers@xylabs.com">careers@xylabs.com</Link>
            {' '}
            with the title of the position that you are
            applying for and with your resume attached.
          </Typography>
        </FlexRow>
        <FlexRow margin={2}>
          <Typography variant="body1">If you are considering a creative role, please also include a link to your portfolio.</Typography>
        </FlexRow>
        <FlexRow margin={2}>
          <Typography variant="body1">If you are considering a development role, please include a link to your Github account.</Typography>
        </FlexRow>
      </FlexGrowCol>
    </JobsBasePage>
  )
}

export default ApplyPage
