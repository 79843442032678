import { Container, Divider, Grid, useMediaQuery, useTheme } from '@mui/material'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

import { BasicHero, CommonImages } from '../../common'
import BasePage from '../BasePage'
import FilingCard from './FilingCard'
import filingData from './FilingData'

const FilingsPage: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <BasePage name="Filings">
      <BasicHero
        title="SEC Filings"
        desc="We are a publicly-held company that files a semi-annual reports with the Securities and Exchange Commission twice a year."
        backgroundImage={isMobile ? '' : CommonImages.booksRight}
      />
      <Divider />
      <Container>
        <SectionSpacingRow>
          <Grid container>
            {filingData.map((filing, index) => {
              return (
                <Grid item key={index} xs={12}>
                  <FilingCard title={filing.title} link={filing.link} type={filing.type} date={filing.date} dark={!(index % 2)} />
                </Grid>
              )
            })}
          </Grid>
        </SectionSpacingRow>
      </Container>
    </BasePage>
  )
}

export default FilingsPage
