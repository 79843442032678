import { Container, Divider, Grid, Typography } from '@mui/material'
import { FlexCol, FlexGrowRow } from '@xylabs/react-flexbox'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React, { SetStateAction, useState } from 'react'

import { BasicHero, SimpleAccordionCard } from '../../common'
import BasePage from '../BasePage'
import { OpenPositionsData } from './OpenPositionsData'

export const AccountantPage: React.FC = () => {
  const [accordionOpenItem, setAccordionOpenItem] = useState<string | null>(null)
  return (
    <BasePage name="Open Positions">
      <BasicHero title="Open Positions at XY Labs" desc="Join us in our mission to easily data-ify the world!" />
      <Divider />
      <FlexGrowRow>
        <Container>
          <SectionSpacingRow>
            {OpenPositionsData.map(({ jobTitle, requirements, tasks, contact }, index) => {
              return (
                <SimpleAccordionCard
                  id={index.toString()}
                  key={index}
                  dark={false}
                  expanded={accordionOpenItem === index.toString()}
                  onChangeExpandKey={(id: SetStateAction<string | null>) => setAccordionOpenItem(id)}
                  desc={(
                    <FlexCol alignItems="flex-start" gap={2}>
                      <FlexCol alignItems="flex-start">
                        <Typography variant="h5" gutterBottom>Tasks & Information</Typography>
                        {tasks.map((item, index) => {
                          return (
                            <Typography variant="body1" key={`task-${index}`}>
                              <li>
                                {item}
                              </li>
                            </Typography>
                          )
                        })}
                      </FlexCol>
                      <FlexCol alignItems="flex-start">
                        <Typography variant="h5" gutterBottom>Requirements</Typography>
                        {requirements.map((item, index) => {
                          return (
                            <Typography variant="body1" key={`requirement-${index}`}>
                              <li>
                                {item}
                              </li>
                            </Typography>
                          )
                        })}
                      </FlexCol>
                      <FlexCol alignItems="flex-start">
                        <Typography variant="h5" gutterBottom>Contact</Typography>
                        <Typography variant="body1">{contact}</Typography>
                      </FlexCol>
                    </FlexCol>
                  )}
                  headline={<Typography variant="h4">{jobTitle}</Typography>}
                />
              )
            })}
          </SectionSpacingRow>
        </Container>
      </FlexGrowRow>
    </BasePage>
  )
}
