import { ExpandMoreRounded } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  alpha,
  MenuItem,
  MenuList,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Override } from '@xylabs/sdk-js'
import { LinkEx } from '@xylabs/sdk-react'
import React, { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { NavbarSectionProps } from './NavbarDropdownDataProps'

interface MobileAccordionAdditionalProps extends AccordionProps, NavbarSectionProps {
  dark?: boolean
  index: number
}

interface OptionalChildren {
  children?: ReactNode
}

// this makes the requirement for children from AccordionProps go away
type MobileAccordionProps = Override<MobileAccordionAdditionalProps, OptionalChildren>
export const MobileAccordion: React.FC<MobileAccordionProps> = ({ name, index, dark, sectionItems, ...props }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [expanded, setExpanded] = useState<string | false>(false)
  const keyString = index + 'card'
  const navigate = useNavigate()

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Accordion
      expanded={expanded === keyString}
      onChange={handleChange(keyString)}
      elevation={0}
      style={{
        backgroundColor: dark ? undefined : alpha(theme.palette.primary.main, 0.05),
      }}
      {...props}
    >
      <AccordionSummary expandIcon={<ExpandMoreRounded />} aria-controls="panel1bh-content" id="panel1bh-header">
        <Typography variant="h6" textAlign="left" gutterBottom={isMobile ? true : false}>
          {name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <MenuList>
          {sectionItems?.map((item, index) => {
            return (
              <MenuItem key={index} onClick={() => navigate(item.to ?? '')}>
                <LinkEx color="inherit" to={item.to ?? ''}>
                  {item.linkText}
                </LinkEx>
              </MenuItem>
            )
          })}
        </MenuList>
      </AccordionDetails>
    </Accordion>
  )
}
