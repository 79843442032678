import { RedirectWithQuery } from '@xylabs/sdk-react'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { ApplyPage } from '../Apply'
import { AccountantPage } from './AccountantPage'
import { JobsPage } from './Jobs'

export const JobsRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<JobsPage />} path="/" />
      <Route element={<ApplyPage />} path="/apply" />
      <Route element={<AccountantPage />} path="/accountant" />
      <Route element={<RedirectWithQuery to="/" />} path="/*" />
    </Routes>
  )
}
