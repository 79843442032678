import { Slider, Typography } from '@mui/material'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import React, { useState } from 'react'

import { TwoColumnSection } from '../../common'

export const FontSection: React.FC = () => {
  const [fontWeight, setFontWeight] = useState<number | string | Array<number | string>>(400)
  const [sliderValue, setSliderValue] = useState<number | string | Array<number | string>>(400)
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setSliderValue(newValue)
    setFontWeight(newValue)
    console.log(newValue)
  }

  const marks = [
    {
      label: '100',
      value: 100,
    },
    {
      label: '200',
      value: 200,
    },
    {
      label: '300',
      value: 300,
    },
    {
      label: '400',
      value: 400,
    },
    {
      label: '500',
      value: 500,
    },
    {
      label: '600',
      value: 600,
    },
    {
      label: '700',
      value: 700,
    },
  ]

  return (
    <TwoColumnSection
      title="Brand Font"
      desc='We use the Google Font "Outfit" for XY Labs typography.'
      href="https://fonts.google.com/specimen/Outfit"
      buttonText="View Outfit"
      height="short"
      column2children={(
        <FlexGrowCol>
          <Typography variant="h1" gutterBottom fontWeight={fontWeight}>
            The quick brown fox jumps over the lazy dog.
          </Typography>
          <Slider
            step={100}
            marks={marks}
            min={100}
            max={700}
            defaultValue={300}
            value={typeof sliderValue === 'number' ? sliderValue : 0}
            onChange={handleSliderChange}
          />
        </FlexGrowCol>
      )}
    />
  )
}
