import { Grid, Typography, useTheme } from '@mui/material'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import React from 'react'

import { TwoColumnSection } from '../../common'
import { useSettings } from '../../Contexts'
import { ColorCard } from './ColorCard'

export const ColorSection: React.FC = () => {
  const theme = useTheme()
  const { darkMode } = useSettings()
  const darkModeString = 'In Dark Mode,'
  const lightModeString = 'In Light Mode,'
  const colorBreakdownString = ` we use ${theme.palette.primary.main} as a primary color for our brand, and ${theme.palette.secondary.main} as an accent color.`
  const brandColorDesc: string = darkMode ? darkModeString.concat(colorBreakdownString) : lightModeString.concat(colorBreakdownString)

  return (
    <TwoColumnSection
      title="Brand Colors"
      desc={brandColorDesc}
      height="short"
      column2children={(
        <FlexGrowCol alignItems="flex-start">
          <Typography marginTop={1} gutterBottom variant="h6">
            Primary:
          </Typography>
          <Grid container alignItems="flex-start" justifyContent="space-between" width="100%" spacing={1}>
            <Grid item xs={12} md={4}>
              <ColorCard color={theme.palette.primary.light} title="Light" />
            </Grid>
            <Grid item xs={12} md={4}>
              <ColorCard color={theme.palette.primary.main} title="Main" />
            </Grid>
            <Grid item xs={12} md={4}>
              <ColorCard color={theme.palette.primary.dark} title="Dark" />
            </Grid>
          </Grid>
          <Typography marginTop={1} gutterBottom variant="h6">
            Secondary:
          </Typography>
          <Grid container alignItems="flex-start" justifyContent="space-between" width="100%" spacing={1}>
            <Grid item xs={12} md={4}>
              <ColorCard color={theme.palette.secondary.light} title="Light" />
            </Grid>
            <Grid item xs={12} md={4}>
              <ColorCard color={theme.palette.secondary.main} title="Main" />
            </Grid>
            <Grid item xs={12} md={4}>
              <ColorCard color={theme.palette.secondary.dark} title="Dark" />
            </Grid>
          </Grid>
        </FlexGrowCol>
      )}
    />
  )
}
