import { BasicHero } from '@xyo-network/react-shared'
import React, { RefObject, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import BasePage from '../BasePage'
import ListOfAvailableJobs from './JobsList'
import Location from './Location'
import Who from './Who'

export const JobsPage: React.FC = () => {
  const location = useLocation()

  const locationAnchor = useRef<HTMLDivElement>(null)
  const missionAnchor = useRef<HTMLDivElement>(null)
  const opportunitiesAnchor = useRef<HTMLDivElement>(null)
  const whoAnchor = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const anchors: Record<string, RefObject<HTMLDivElement>> = {
      location: locationAnchor,
      mission: missionAnchor,
      opportunities: opportunitiesAnchor,
      who: whoAnchor,
    }
    const anchor = anchors[location.hash.slice(1)]
    if (anchor?.current) {
      anchor?.current.scrollIntoView({
        behavior: 'smooth',
      })
    } else {
      console.log('Ooops')
    }
  }, [locationAnchor, location])

  return (
    <BasePage name="Jobs">
      <BasicHero
        title="Change your future at XY Labs"
        desc="A great place to work for exceptional people that combines innovation, challenges, learning, and excellent compensation."
        button1Text="Apply Now"
        button1To="/jobs/apply"
      />
      <Who />
      <Location />
      <ListOfAvailableJobs />
    </BasePage>
  )
}
