import UserEvents from './UserEvents'

// we call this outside the hook to force initialization on page load
const instance = UserEvents.get()

const useUserEvents = () => {
  return instance
}

export { useUserEvents }

export { default as UserEventId } from './UserEventId'
export { default as UserEvents } from './UserEvents'
