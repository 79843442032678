import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FlexGrowCol, FlexRow } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

import { BasicHero, PlantsRightParallax } from '../../common'
import BasePage from '../BasePage'
const MissionPage: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <BasePage name="Mission">
      <BasicHero title="Our Mission" desc="What do we stand for?" parallaxBackground={<PlantsRightParallax />} />
      <Divider />
      <SectionSpacingRow height="70vh" maxHeight="700px">
        <Container>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={6}>
              <FlexGrowCol width="100%" alignItems="center">
                <Typography variant={isMobile ? 'h3' : 'h2'} textAlign="center" sx={{ letterSpacing: 2 }} gutterBottom>
                  How do you know you own your data?
                </Typography>
                <Typography variant="body1" gutterBottom textAlign="center">
                  Data is exciting, huge, and tough to wrangle. How do you know your data is safe, and how do you know you&apos;re the one who owns
                  it?
                </Typography>
                <Typography variant="body1" gutterBottom textAlign="center">
                  At the end of the day, the &quot;data-economy&quot; we have built has to be secure, trusted, and seamless with our physical,
                  everyday life.
                </Typography>
              </FlexGrowCol>
            </Grid>
          </Grid>
        </Container>
      </SectionSpacingRow>
      <SectionSpacingRow height="70vh" maxHeight="700px">
        <Container>
          <Grid container justifyContent="center" alignItems="center" flexDirection="column">
            <Grid item xs={12} md={5}>
              <Typography variant={isMobile ? 'h3' : 'h2'} textAlign="center" sx={{ letterSpacing: 2 }} gutterBottom>
                That&apos;s why we started XY Labs.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" gutterBottom textAlign="center">
                What started as an idea for a seamless location network between our real and virtual realities has evolved into a data-driven company
                that strives to create products to make our lives in the data-economy easy.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </SectionSpacingRow>
      <SectionSpacingRow>
        <Container>
          <Grid container alignContent="center" justifyContent="center">
            <Grid item xs={12} md={8}>
              <FlexRow width="100%" alignItems="flex-start">
                <Typography variant="h1" color="inherit">
                  &quot; ...a seamless location network between our real and virtual realities... &quot;
                </Typography>
              </FlexRow>
            </Grid>
          </Grid>
        </Container>
      </SectionSpacingRow>
      <SectionSpacingRow height="70vh" maxHeight="700px">
        <Container>
          <Grid container justifyContent="center" alignItems="center" flexDirection="column">
            <Grid item xs={12} md={6}>
              <Typography variant={isMobile ? 'h3' : 'h2'} textAlign="center" sx={{ letterSpacing: 2 }} gutterBottom>
                Data should be easy.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" gutterBottom textAlign="center">
                Whether it&apos;s for consumers, developers, or businesses, we want to give everyone the chance to participate, benefit, and grow from
                the global data-economy. The key to a world of possibility.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </SectionSpacingRow>
    </BasePage>
  )
}

export default MissionPage
