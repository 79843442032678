import { ArrowForwardRounded } from '@mui/icons-material'
import { Card, CardContent, Container, Grid, Typography } from '@mui/material'
import { ButtonEx, FlexGrowCol } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

const InvestorsFaqSection: React.FC = () => {
  return (
    <SectionSpacingRow>
      <Container>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">What is my XY Equity worth?</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">Is XY listed on an exchange?</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">How do I buy more shares?</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">How do I contact the team?</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5}>
            <FlexGrowCol width="100%" alignItems="flex-start" paddingY={2}>
              <Typography variant="h2" gutterBottom>
                Have questions?
              </Typography>
              <Typography variant="h6" gutterBottom>
                Explore FAQs on our website, or check out our extensive XY Investor Help Center for answers to some of the most popular inquiries from
                our shareholders.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Have a question that isn&apos;t covered? Contact the XY Labs Investor Relations Team for help!
              </Typography>
              <ButtonEx marginTop={1} variant="contained" color="primary" to="/investors/faq" endIcon={<ArrowForwardRounded />}>
                View FAQ
              </ButtonEx>
            </FlexGrowCol>
          </Grid>
        </Grid>
      </Container>
    </SectionSpacingRow>
  )
}

export default InvestorsFaqSection
