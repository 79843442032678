import { Divider } from '@mui/material'
import { BasicHero } from '@xyo-network/react-shared'
import React from 'react'

import BasePage from '../BasePage'
import CoinExplained from './Explained'
import CoinQuote from './Quote'
import CoinVideo from './Video'

const CoinAppPage: React.FC = () => {
  return (
    <BasePage name="COIN App">
      <BasicHero
        title="COIN App: Your Data, Rewarded"
        desc="Earn crypto for your time, attention, and data!"
        button1Text="Explore COIN App"
        button1Href="https://coinapp.co"
        button2Href="https://coinapp.co/download"
        button2Text="Download COIN app"
        subLinkPath="https://support.coinapp.co"
        subLinkText1="Need help with COIN?"
        subLinkText2="Get Support Here."
      />
      <Divider />
      <CoinExplained />
      <CoinQuote />
      <CoinVideo />
    </BasePage>
  )
}

export default CoinAppPage
