import { Container, Divider, Grid, useMediaQuery, useTheme } from '@mui/material'
import { FlexGrowRow } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

import { BasicHero, CommonImages } from '../../common'
import BasePage from '../BasePage'
import TeamCard from './TeamCard'
import teamData from './teamData'

const TeamPage: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <BasePage name="Team">
      <BasicHero
        title="Meet the Team"
        desc="Meet the people who make up our US-based, remote team!"
        backgroundImage={isMobile ? '' : CommonImages.constructionRight}
      />
      <Divider />
      <FlexGrowRow>
        <Container>
          <SectionSpacingRow>
            <Grid container>
              {teamData.map((employee, index) => {
                return (
                  <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
                    <TeamCard image={employee.image} name={employee.name} bio={employee.bio} role={employee.role} motto={employee.motto} />
                  </Grid>
                )
              })}
            </Grid>
          </SectionSpacingRow>
        </Container>
      </FlexGrowRow>
    </BasePage>
  )
}

export default TeamPage
