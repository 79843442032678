import { Card, CardContent, CardProps, Typography, useTheme } from '@mui/material'
import React from 'react'

export interface ColorCardProps extends CardProps {
  color: string
}

export const ColorCard: React.FC<ColorCardProps> = ({ title, color, ...props }) => {
  const theme = useTheme()
  const textColor = theme.palette.getContrastText(color)
  return (
    <Card
      style={{
        backgroundColor: color,

        width: '100%',
      }}
      {...props}
    >
      <CardContent>
        <Typography color={textColor} variant="body1">
          {title}
        </Typography>
        <Typography color={textColor} variant="body2">
          {(color.startsWith('rgb') ? `#${color}` : color).toLowerCase()}
        </Typography>
      </CardContent>
    </Card>
  )
}
