import { Container, Typography } from '@mui/material'
import { FlexGrowCol } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

const XyFinditExplained: React.FC = () => {
  return (
    <SectionSpacingRow>
      <Container>
        <FlexGrowCol>
          <Typography variant="body1" gutterBottom textAlign="left">
            Dear Valued XY Find It Customers,
            <br />
            <br />
            It is with a mix of emotions that we write to you today, bearing news about the deprecation of our longtime flagship product, XY Find It,
            first introduced in 2014. After careful consideration and evaluation of our product offerings, we have made the difficult decision to
            permanently retire XY Find It.
            <br />
            <br />
            This journey with XY Find It has been incredible, and we are deeply grateful for the trust and loyalty you have shown us over the years.
            Your support has been instrumental in shaping XY Find It into the innovative product it became, and for that, we extend our heartfelt
            thanks.
            <br />
            <br />
            While we understand that this news may come as a surprise, please know that this decision was not made lightly. As technology and market
            dynamics evolve, we must adapt to meet the changing needs of our customers. Though we bid farewell to XY Find It, we remain committed to
            providing you with cutting-edge solutions that enhance your daily life. We encourage you to explore our latest offerings and innovations
            as we continue to strive for excellence in the products and services we provide. Our commitment to delivering quality remains unwavering,
            and we look forward to serving you with even more exciting developments in the future.
            <br />
            <br />
            If you purchased XY Find It products recently, please contact the XY Labs support team and we will be glad to check your eligibility for a
            full refund. Once again, thank you for being an integral part of the XY Labs journey. We are truly honored to have had the opportunity to
            serve you, and we hope to continue this journey together with our new and upcoming products.
            <br />
            <br />
            Wishing you all the best in your future endeavors.
            <br />
            <br />
            Warm regards,
            <br />
            <br />
            The XY Labs Team
          </Typography>
        </FlexGrowCol>
      </Container>
    </SectionSpacingRow>
  )
}

export default XyFinditExplained
