import { initialize, track, updateUser } from '@iterable/web-sdk'

export interface UserEmail {
  email: string
}

export interface UserId {
  userId: string
}

// restricted client side js key
const ITERABLE_API_KEY = 'c6caecabbffe47cba08ba48818ffbf2f'

const { setEmail } = initialize(ITERABLE_API_KEY, async (_payload): Promise<string> => await Promise.resolve(`${Date.now()}`))

export type User = UserEmail | UserId

export type TrackEvent = User & {
  campaignId?: number
  createNewFields?: boolean
  createdAt?: number
  dataFields?: Record<string, unknown>
  eventName: string
  templateId?: number
}

export const iterableTrackEvent = async (event: TrackEvent) => {
  if ((event as UserEmail).email) {
    await setEmail((event as UserEmail).email)
    await updateUser()
  }
  await track(event)
}
