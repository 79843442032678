import { BoxProps, Typography } from '@mui/material'
import { FlexCol, LinkEx } from '@xylabs/sdk-react'
import React from 'react'

const Equity: React.FC<BoxProps> = (props) => {
  return (
    <FlexCol {...props}>
      <Typography variant="h6">XY Equity</Typography>
      <LinkEx color="inherit" to="/investors/resources">
        Resources
      </LinkEx>
      <LinkEx color="inherit" to="/filings">
        SEC Filings
      </LinkEx>
      <LinkEx color="inherit" to="/investors/faq">
        FAQ
      </LinkEx>
      <LinkEx color="inherit" to="/investors/support">
        Support
      </LinkEx>
    </FlexCol>
  )
}

export default Equity
