import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FlexBoxProps, FlexGrowCol, FlexGrowRow } from '@xylabs/react-flexbox'
import { ButtonEx } from '@xylabs/sdk-react'
import React from 'react'

import { AnimatedText } from './AnimatedText'
import { HeroBackgroundParallax, MobileHeroBackgroundParallax } from './Parallax'

export interface HomePageHeroProps extends FlexBoxProps {
  animatedText?: string[]

  button1Href?: string
  button1Text?: string
  button1To?: string
  buttonOnClick?: React.MouseEventHandler<HTMLButtonElement>
  desc: string
  heroImage?: string
  sx?: Record<string, string>
  textColor?: string
  title: string
  title2: string
}

export const HomePageHero: React.FC<HomePageHeroProps> = ({
  title,
  title2,
  animatedText,
  textColor,
  desc,
  button1Text,
  button1To,
  button1Href,
  buttonOnClick,
  sx,
  ...props
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <FlexGrowCol
      sx={{
        backgroundPosition: { lg: 'bottom' },
        justifyContent: 'flex-end',
        ...sx,
      }}
      style={{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: textColor ?? '',
      }}
      {...props}
    >
      <FlexGrowCol sx={{ height: { lg: '100%', md: '70%', sm: '50%', xs: '100%' }, maxHeight: '1000px' }} width="100%" position="absolute">
        {isMobile
          ? <MobileHeroBackgroundParallax />
          : <HeroBackgroundParallax />}
      </FlexGrowCol>
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <Grid container alignContent="center" justifyContent="center" height="100%">
          <Grid item xs={12} sm={8} md={6}>
            <FlexGrowCol alignItems="center" alignSelf="center">
              <Typography variant="h1" gutterBottom textAlign="center">
                {title ?? null}
                {animatedText
                  ? <AnimatedText text={animatedText} />
                  : null}
                {' '}
                <br></br>
                {title2 ?? null}
              </Typography>
              <Typography variant="body1" component="h2" gutterBottom textAlign="center">
                {desc}
              </Typography>
              <FlexGrowRow sx={{ flexDirection: { lg: 'row', xs: 'column' } }} width="80%" marginTop={1}>
                <ButtonEx
                  color="primary"
                  fullWidth={true}
                  target={button1Href == undefined ? '' : '_blank'}
                  to={button1To}
                  href={button1Href}
                  variant="contained"
                  sx={{
                    marginBottom: 1,
                    marginLeft: isMobile ? 2 : 0,
                    marginRight: isMobile ? 2 : 1,
                    marginTop: 1,
                  }}
                  onClick={buttonOnClick}
                >
                  {button1Text}
                </ButtonEx>
              </FlexGrowRow>
            </FlexGrowCol>
          </Grid>
        </Grid>
      </Container>
    </FlexGrowCol>
  )
}
