import { BoxProps, Typography } from '@mui/material'
import { FlexCol, LinkEx } from '@xylabs/sdk-react'
import React from 'react'

const Other: React.FC<BoxProps> = (props) => {
  return (
    <FlexCol {...props}>
      <Typography variant="h6">Other</Typography>
      <LinkEx color="inherit" to="/brand">
        Brand
      </LinkEx>
      <LinkEx color="inherit" to="/dataism">
        Dataism
      </LinkEx>
      <LinkEx color="inherit" to="/jobs">
        Careers
      </LinkEx>
    </FlexCol>
  )
}

export default Other
