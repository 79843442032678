import FilingDataProps from './FilingDataProps'

const filingData: FilingDataProps[] = [
  {
    date: '2024-09-30',
    link: 'https://www.sec.gov/Archives/edgar/data/1577351/000110465924104278/tm2425145d1_1sa.htm',
    title: '2024 Semi-Annual Report',
    type: 'Form 1-SA',
  },
  {
    date: '2024-04-29',
    link: 'https://www.sec.gov/Archives/edgar/data/1577351/000119312524122692/d831700dpartii.htm',
    title: '2023 Annual Report',
    type: 'Form 1-K',
  },
  {
    date: '2023-09-28',
    link: 'https://www.sec.gov/Archives/edgar/data/1577351/000119312523245777/d457880d1sa.htm',
    title: '2023 Semi-Annual Report',
    type: 'Form 1-SA',
  },
  {
    date: '2023-05-01',
    link: 'https://www.sec.gov/Archives/edgar/data/1577351/000119312523130696/d481170dpartii.htm',
    title: '2022 Annual Report',
    type: 'Form 1-K',
  },
  {
    date: '2022-09-28',
    link: 'https://www.sec.gov/Archives/edgar/data/1577351/000119312522252706/d390612d1sa.htm',
    title: '2022 Semi-Annual Report',
    type: 'Form 1-SA',
  },
  {
    date: '2022-05-02',
    link: 'https://www.sec.gov/Archives/edgar/data/1577351/000119312522136699/d348799dpartii.htm',
    title: '2021 Annual Report',
    type: 'Form 1-K',
  },
  {
    date: '2021-09-28',
    link: 'https://www.sec.gov/Archives/edgar/data/0001577351/000119312521285602/d154337d1sa.htm',
    title: '2021 Semi-Annual Report',
    type: 'Form 1-SA',
  },
  {
    date: '2021-04-30',
    link: 'https://www.sec.gov/Archives/edgar/data/1577351/000119380521000579/e620540_1k-xy.htm',
    title: '2020 Annual Report',
    type: 'Form 1-K',
  },
  {
    date: '2020-09-28',
    link: 'https://www.sec.gov/Archives/edgar/data/1577351/000119312520256118/d48525d1sa.htm',
    title: '2020 Semi-Annual Report',
    type: 'Form 1-SA',
  },
  {
    date: '2020-05-19',
    link: 'https://www.sec.gov/Archives/edgar/data/1577351/000119312520146109/d926085dpartii.htm',
    title: '2019 Annual Report',
    type: 'Form 1-K',
  },
  {
    date: '2019-09-18',
    link: 'https://www.sec.gov/Archives/edgar/data/1577351/000119312519247140/d793299d1sa.htm',
    title: '2019 Semi-Annual Report',
    type: 'Form 1-SA',
  },
  {
    date: '2019-05-01',
    link: 'https://www.sec.gov/Archives/edgar/data/1577351/000119312519133301/d689828dpartii.htm',
    title: '2018 Annual Report',
    type: 'Form 1-K',
  },
  {
    date: '2018-09-26',
    link: 'https://www.sec.gov/Archives/edgar/data/1577351/000119312518284062/d591010d1sa.htm',
    title: '2018 Semi-Annual Report',
    type: 'Form 1-SA',
  },
  {
    date: '2018-07-17',
    link: 'https://www.sec.gov/Archives/edgar/data/1577351/000119312518217650/d491725dpartiiandiii.htm',
    title: 'New 2018 Offering Statement (Second Round) [Regulation A][Amend]',
    type: 'Form 1-A POS',
  },
  {
    date: '2018-04-30',
    link: 'https://www.sec.gov/Archives/edgar/data/1577351/000119312518143726/d579201dpartii.htm',
    title: '2017 Annual Report',
    type: 'Form 1-K',
  },
  {
    date: '2018-03-19',
    link: 'https://www.sec.gov/Archives/edgar/data/1577351/000119312518143726/d579201dpartii.htm',
    title: '2018 Offering Statement (Second Round) [Regulation A]',
    type: 'Form 1-A POS',
  },
  {
    date: '2017-09-21',
    link: 'https://www.sec.gov/Archives/edgar/data/0001577351/000119312521285602/d154337d1sa.htm',
    title: '2017 Semi-Annual Report',
    type: 'Form 1-SA',
  },
  {
    date: '2017-09-28',
    link: 'https://www.sec.gov/Archives/edgar/data/1577351/000119312517297611/d465030dpartiiandiii.htm',
    title: '2017 Offering Statement (First Round) [Regulation A] [Amend]',
    type: 'Form 1-A/A',
  },
  {
    date: '2016-09-29',
    link: 'https://www.sec.gov/Archives/edgar/data/1577351/999999999416000104/xslQUALIFX01/primary_doc.xml',
    title: '2016 Notice of Qualification [Regulation A]',
    type: 'Form QUALIF',
  },
]

export default filingData
