import { QuestionAnswerRounded } from '@mui/icons-material'
import { Container, Divider, Grid } from '@mui/material'
import { FlexCol, FlexGrowRow } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React, { SetStateAction, useState } from 'react'

import { BasicHero, SimpleAccordionCard } from '../../../common'
import BasePage from '../../BasePage'
import InvestorResourcesData from './InvestorFaqData'

export const InvestorsFaqPage: React.FC = () => {
  const [accordionOpenItem, setAccordionOpenItem] = useState<string | null>(null)

  return (
    <BasePage name="Investor FAQs">
      <BasicHero
        title="Shareholder FAQ"
        desc="Have questions about your shareholding, tZERO, Vertalo, or something else? Check out the FAQ below, or contact our team for assistance with your investment."
        subLinkText1="Need Help With Your Equity?"
        subLinkText2="Get Support Here."
        subLinkIcon={<QuestionAnswerRounded />}
      />
      <Divider />
      <FlexGrowRow>
        <Container>
          <SectionSpacingRow>
            <Grid container>
              {InvestorResourcesData.map(({ desc, headline, href, to }, index) => {
                return (
                  <Grid item key={index} xs={12}>
                    <FlexCol justifyContent="flex-start" alignItems="stretch" margin={0.5}>
                      <SimpleAccordionCard
                        id={index.toString()}
                        key={index}
                        dark={!(index % 2)}
                        expanded={accordionOpenItem === index.toString()}
                        onChangeExpandKey={(id: SetStateAction<string | null>) => setAccordionOpenItem(id)}
                        desc={desc}
                        headline={headline}
                        href={href}
                        to={to}
                      />
                    </FlexCol>
                  </Grid>
                )
              })}
            </Grid>
          </SectionSpacingRow>
        </Container>
      </FlexGrowRow>
    </BasePage>
  )
}
