import { Divider, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { BasicHero, CommonImages, TwoColumnSection } from '../../common'
import BasePage from '../BasePage'
import { ColorSection } from './ColorSection'
import { FontSection } from './FontSection'
import { LogoSelection } from './LogoSelection'

interface BrandFontWeight {
  weight?: number
}

const BrandPage: React.FC<BrandFontWeight> = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <BasePage name="Brand">
      <BasicHero
        title="The XY Brand"
        desc="Our colors, fonts, and design standards."
        backgroundImage={isMobile ? '' : CommonImages.brandHero}
        centeredAlways
      />
      <Divider />
      <TwoColumnSection
        height="short"
        title="XYO Logo"
        desc="The XY Labs Logo was designed to be a consistent image used in any of our communications. Please do not re-color or edit XY's logo."
        column2children={<LogoSelection />}
      />
      <Divider />
      <ColorSection />
      <Divider />
      <FontSection />
    </BasePage>
  )
}

export default BrandPage
