import React from 'react'

import { JobsAppBar } from '../AppBar'
import BasePage from './BasePage'
import { BasePageProps } from './BasePageProps'

const JobsBasePage: React.FC<BasePageProps> = ({ appBar, ...props }) => {
  return <BasePage appBar={appBar ?? <JobsAppBar />} {...props} />
}

export default JobsBasePage
