interface OpenPositionsProps {
  contact: string
  jobTitle: string
  requirements: string[]
  tasks: string[]
}

export const OpenPositionsData: OpenPositionsProps[] = [
  {
    tasks: ['Responsible for transactional accounting, general ledger account reconciliations, accounts payable/receivable, expense reporting, financial reporting, and auditing', ' Record day-to-day financial transactions including sales, receipts, and payments in Xero',
      'Verify accuracy and completeness of data, ensuring adherence to US GAAP',
      'Conduct daily reconciliations of general ledger accounts to guarantee the accuracy and reliability of financial reporting',
      'Investigate and resolve discrepancies to maintain the integrity of financial statements', ' Manage accounts payable by accurately recording and tracking invoices, process payments, and ensure timely disbursements to vendors and suppliers',
      'Interact with vendors, prepare invoices, and manage collections to maintain positive cash flow',
      'Process contractor bonus and commission payments, verifying compliance with company policies and guidelines',
      'Process employee expense requests',
      'Examine and reconcile payroll information through Gusto',
      'Maintain employer accounts in multiple states of the US',
      'Communicate with States, agencies, and Gusto to solve accounts and tax issues',
      'Complete tax filings, including State sales tax and business property tax', ' Prepare W-2s and 1099s. Maintain contractor records',
      "Generate financial reports to provide insight into the organization's financial performance, assisting management in decision-making processes",
      'Maintain business property statements, assets registers, depreciation schedules, loan amortization schedules in compliance with US GAAP',
      'Prepare responses to business surveys, insurance questionnaires and other inquiries by collecting and analyzing data from financial reports',
      ' Furnish essential documentation and address inquiries for audits in preparation and submission of annual for 10k filings', 'Telecommuting is permitted'],
    requirements: ["Master's Degree in Accounting"],
    jobTitle: 'Accountant',
    contact: 'Email resume to markus@xy.company attn Markus Levin.',
  },
]
