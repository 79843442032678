import { Container, Grid, Typography } from '@mui/material'
import { FlexGrowCol } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

import { VertaloPlatform } from './img'
export const VertaloExplained: React.FC = () => {
  return (
    <SectionSpacingRow>
      <Container>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} md={8}>
            <FlexGrowCol paddingBottom={3}>
              <Typography variant="h5" textAlign="center" gutterBottom>
                Vertalo Digital Asset Management Platform
              </Typography>
              <Typography variant="body1" textAlign="center" gutterBottom>
                By coordinating with its network of integrated partners, Vertalo reduces systemic friction in private capital formation and improves
                access to secondary liquidity. Alongside Vertalo&apos;s long-time partner tZERO, Vertalo and XY Labs will offer an improved
                shareholder experience.
              </Typography>
            </FlexGrowCol>
          </Grid>
          <Grid item xs={12}>
            <FlexGrowCol>
              <img src={VertaloPlatform} width="100%" height="auto" />
            </FlexGrowCol>
          </Grid>
        </Grid>
      </Container>
    </SectionSpacingRow>
  )
}
