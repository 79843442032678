import { Typography } from '@mui/material'
import { FlexGrowCol } from '@xylabs/sdk-react'
import React, { useState } from 'react'

import { SimpleAccordionCard } from '../../../common'

export const TzeroInvestorOptionsAccordion: React.FC = () => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <FlexGrowCol alignItems="stretch">
      <Typography variant="body1" textAlign="left" gutterBottom>
        tZERO will ask a few questions about your investor plans. As a broker dealer, tZERO is required to keep a record of their customers&apos;
        investment objectives.
      </Typography>
      <Typography variant="body2" textAlign="left" gutterBottom>
        <i>Note: Shareholders and potential Shareholders of XY Labs should do their own research to determine which selection is best for them.</i>
      </Typography>
      <FlexGrowCol paddingTop={1} alignItems="stretch">
        <SimpleAccordionCard
          headline="Capital Appreciation"
          desc={(
            <Typography variant="body2" textAlign="left" gutterBottom>
              &quot;Capital appreciation is often a stated investment goal of many mutual funds. These funds look for investments that will rise in
              value based on increased earnings or other fundamental metrics.&quot;
            </Typography>
          )}
          href="https://www.investopedia.com/terms/c/capitalappreciation.asp#:~:text=Investing%20for%20Capital%20Appreciation,earnings%20or%20other%20fundamental%20metrics"
          key="appreciation"
          expanded={expanded === 'appreciation'}
          onChange={handleChange('appreciation')}
        >
        </SimpleAccordionCard>
      </FlexGrowCol>
      <FlexGrowCol paddingTop={1} alignItems="stretch">
        <SimpleAccordionCard
          dark={false}
          headline="Speculation"
          desc={(
            <Typography variant="body2" textAlign="left" gutterBottom>
              &quot;In the world of finance, speculation, or speculative trading, refers to the act of conducting a financial transaction that has
              substantial risk of losing value but also holds the expectation of a significant gain or other major value. An investor who purchases a
              speculative investment is likely focused on price fluctuations.&quot;
            </Typography>
          )}
          href="https://www.investopedia.com/terms/s/speculation.asp"
          key="speculation"
          expanded={expanded === 'speculation'}
          onChange={handleChange('speculation')}
        >
        </SimpleAccordionCard>
      </FlexGrowCol>
      <FlexGrowCol paddingTop={1} alignItems="stretch">
        <SimpleAccordionCard
          headline="Profit Target"
          desc={(
            <Typography variant="body2" textAlign="left" gutterBottom>
              &quot;A profit target is a predetermined price point at which an investor will exit a trade for a positive gain. Profit targets are part
              of many trading strategies that investors and technical traders use to manage risk, and the target can be set using one of many
              techniques or criteria.&quot;
            </Typography>
          )}
          href="https://www.investopedia.com/terms/p/profit-target.asp"
          key="profitTarget"
          expanded={expanded === 'profitTarget'}
          onChange={handleChange('profitTarget')}
        >
        </SimpleAccordionCard>
      </FlexGrowCol>
      <FlexGrowCol paddingTop={1} alignItems="stretch">
        <SimpleAccordionCard
          headline="Illiquid Securities"
          desc={(
            <Typography variant="body2" textAlign="left" gutterBottom>
              Illiquid securities are securities that cannot be sold quickly due to a shortage of interested buyers or a lack of an established
              trading market.
            </Typography>
          )}
          href="https://www.investopedia.com/terms/i/illiquid.asp"
          key="illiquidSecurities"
          expanded={expanded === 'illiquidSecurities'}
          onChange={handleChange('illiquidSecurities')}
        >
        </SimpleAccordionCard>
      </FlexGrowCol>
    </FlexGrowCol>
  )
}
