import { OpenInNewRounded } from '@mui/icons-material'
import { Container, Grid, Typography } from '@mui/material'
import { ButtonEx, FlexCol, FlexGrowCol, FlexGrowRow } from '@xylabs/sdk-react'
import React from 'react'

import { LegoLeftParallax } from '../../common'

export const XYOSection: React.FC = () => {
  return (
    <FlexGrowCol
      sx={{
        backgroundPosition: { lg: 'bottom', xs: 'bottom center' },
        backgroundRepeat: 'no-repeat',
        backgroundSize: { md: 'cover', xs: 'contain' },
      }}
    >
      <FlexGrowCol sx={{ display: { lg: 'flex', xs: 'none' }, height: { xs: '100%' }, maxHeight: '1000px' }} width="100%" position="absolute">
        <LegoLeftParallax />
      </FlexGrowCol>
      <Container>
        <FlexCol sx={{ maxHeight: { sm: '1000px' }, minHeight: { md: '80vh' } }}>
          <Grid container justifyContent="space-between" alignItems="stretch" spacing={2}>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={6}>
              <FlexGrowCol alignItems="flex-start">
                <Typography variant="h2" textAlign="left" gutterBottom>
                  XYO
                </Typography>
                <Typography variant="h6" gutterBottom textAlign="left">
                  XYO is both a cryptographic protocol that improves the certainty and value of data, as well as a self-sovereign data platform allows
                  people to make dApps (decentralized apps) without consensus.
                </Typography>
                <Typography variant="h6" gutterBottom textAlign="left">
                  Anyone can use XYO —&nbsp;
                  <strong>developers</strong>
                  {' '}
                  can utilize XYO&apos;s SDKs in any project,
                  {' '}
                  <strong color="primary">businesses</strong>
                  {' '}
                  can utilize XYO technology to enhance their data certainty, and
                  {' '}
                  <strong>consumers</strong>
                  {' '}
                  can join the world of XYO easily with our mobile application and game, &quot;COIN&quot;.
                </Typography>
                <FlexGrowRow marginTop={3} gap={2}>
                  <ButtonEx to="#coinProduct" variant="contained" color="primary">
                    Learn about XYO with COIN
                  </ButtonEx>
                  <ButtonEx href="https://xyo.network" target="_block" variant="outlined" color="primary">
                    XYO Website &nbsp;
                    {' '}
                    <OpenInNewRounded fontSize="small" />
                  </ButtonEx>
                </FlexGrowRow>
              </FlexGrowCol>
            </Grid>
          </Grid>
        </FlexCol>
      </Container>
    </FlexGrowCol>
  )
}
