import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FlexBoxProps, FlexGrowCol, FlexGrowRow, LinkEx } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

const CompanyMission: React.FC<FlexBoxProps> = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <SectionSpacingRow>
      <Container>
        <Grid container>
          <Grid item xs={12} md={5}>
            <FlexGrowCol width="100%" alignItems="flex-start">
              <Typography variant="h2" gutterBottom>
                Our Mission
              </Typography>
              <Divider />
              <Typography variant="body1" gutterBottom>
                Data is exciting, huge, and tough to wrangle. How do you know your data is safe, and how do you know you&apos;re the one who owns it?
                At the end of the day, the &#34;data-economy&#34; we have built has to be secure, trusted, and seamless with our physical, everyday
                life. That&apos;s why we started XY Labs.
              </Typography>
              <FlexGrowRow width="100%" justifyContent="flex-start" paddingTop={2}>
                <Typography>Curious what drives us?&nbsp;</Typography>
                <LinkEx to="/company/mission" underline="always" color="inherit">
                  Read More&nbsp;
                </LinkEx>
              </FlexGrowRow>
            </FlexGrowCol>
          </Grid>
          <Grid item xs={12} md={1} minHeight="100px"></Grid>
          <Grid item xs={12} md={6}>
            <FlexGrowCol width="100%" height="100%" alignContent="center">
              <Typography variant="h1" textAlign={isMobile ? 'center' : 'left'}>
                Connecting the&nbsp;
                <Typography variant="h1" color="primary" component="span">
                  real&nbsp;
                </Typography>
                <Typography variant="h1" component="span">
                  &amp; the&nbsp;
                </Typography>
                <Typography variant="h1" color="primary" component="span">
                  virtual.
                </Typography>
              </Typography>
            </FlexGrowCol>
          </Grid>
        </Grid>
      </Container>
    </SectionSpacingRow>
  )
}

export default CompanyMission
