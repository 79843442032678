import { ArrowForwardRounded } from '@mui/icons-material'
import { Container, Fade, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import { BusyBoxProps, FlexCol } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import React from 'react'

export const TzeroListingBar: React.FC<BusyBoxProps> = ({ ...props }) => {
  const theme = useTheme()
  const color1 = theme.palette.primary.main
  const color2 = theme.palette.primary.dark
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <FlexCol alignItems="stretch" {...props} position={isMobile ? 'relative' : 'absolute'} bottom={0} width="100%">
      <Fade in={true} timeout={1000} style={{ transitionDelay: '2s' }}>
        <Paper
          elevation={0}
          style={{ background: `linear-gradient(120deg, ${color1}, ${color2})` }}
          sx={{
            borderRadius: 0,
            color: 'white',
            lineHeight: '60px',
            textAlign: 'center',
          }}
        >
          <Container>
            <FlexCol alignItems="center" paddingY={1.5}>
              <LinkEx href="https://www.tzero.com/investors/register" target="_blank" color="inherit">
                <Typography variant="h6" color="inherit" display="flex" alignItems="center">
                  🎉 &nbsp;&nbsp;XY Labs Stock Now Trading!&nbsp;&nbsp;|&nbsp;&nbsp;Make a tZERO Markets Account to Get Started&nbsp;&nbsp;
                  <ArrowForwardRounded fontSize="small" />
                </Typography>
              </LinkEx>
            </FlexCol>
          </Container>
        </Paper>
      </Fade>
    </FlexCol>
  )
}
