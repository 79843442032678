import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FlexGrowCol } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

const CoinExplained: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <SectionSpacingRow>
      <Container>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={4}>
            <FlexGrowCol>
              <Typography variant={isMobile ? 'h2' : 'h1'} gutterBottom textAlign="left">
                What is COIN app?
              </Typography>
            </FlexGrowCol>
          </Grid>
          <Grid item xs={12} md={6}>
            <FlexGrowCol>
              <Typography variant="body1" gutterBottom textAlign="left">
                Launched in early 2019, COIN is a mobile app enabling over 1 million users to earn digital assets for validating geospatial location
                data when traveling, commuting, jogging or moving about the real world while interacting with each other and enjoying in-app
                challenges.
              </Typography>
            </FlexGrowCol>
          </Grid>
        </Grid>
      </Container>
    </SectionSpacingRow>
  )
}

export default CoinExplained
